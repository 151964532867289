@import 'normalize.css/normalize.css';

:root {
  --color-fg: #fff;
  --color-bg: #171720;
  --color-black: #171720;
  --color-black-light: #434758;
  --color-gray-1: #21212a;
  --color-red: #fa3d50;
  --color-red-light: #ff8b92;
  --color-green: #14b479;
  --color-green-light: #ddffa7;
  --color-yellow: #f7b500;
  --color-yellow-light: #ffe585;
  --color-blue: #82aaff;
  --color-blue-light: #9cc4ff;
  --color-magenta: #ae13ff;
  --color-magenta-light: #e1acff;
  --color-cyan: #89ddff;
  --color-cyan-light: #a3f7ff;
  --color-white: #d0d0d0;
  --color-white-light: #ffffff;
  --color-header-bg: #000001;
  --color-transparent: transparent;
  --color-text: #d0d0d0;
  --color-text-muted: #919bbe;

  // button
  --btn-primary-bg: linear-gradient(to right, #ff7c32 -3%, #8f1beb 100%);
  --btn-primary-color: #fff;
  --btn-primary-bg-hover: linear-gradient(to left, #ff7c32 -3%, #8f1beb 100%);

  // modal
  --modal-bg: #1f2134;
  --modal-header-gradient: linear-gradient(to right, #ff7c3233 -3%, #8f1beb33 100%);
  --modal-close-color: #8b8fa1;
  --modal-close-color-hover: #fff;
  --modal-size-sm: 320px;
  --modal-size-md: 600px;
  --modal-size-lg: 800px;
  --modal-size-xl: 1140px;

  // range slider
  --range-track-bg: #526cf2;
  --range-mark-bg: #526cf2;
  --range-thumb-border: 1px solid #526cf2;
  --range-thumb-focus-shadow: 0px 0px 2px 0 #526cf2;

  // box
  --box-border: 2px solid #21212a;
  --box-row-bg: #1f2134;
  --box-row-bg-hover: #191c3b;
  --box-row-supply-bg: #14b4790c;
  --box-row-borrow-bg: #ae13ff0c;
  --box-heading-color: var(--color-text-muted);
  --box-joint-bg: #1f2134;

  // token input
  --token-input-border: solid 2px #2f374d;
  --token-input-bg: #2a2a48;

  // nav tab
  --color-nav-active: var(--color-green);

  // tokeninput
  --token-input-color: var(--color-green);
  --token-input-color-invalid: var(--color-red);
  --token-input-shadow-invalid: 0 0px 4px 0px var(--color-red);

  // popup
  --popup-bg: #000;
}

html {
  font-family: 'Manrope', sans-serif;
  background-color: var(--color-bg);
  color: var(--color-text);
  font-size: 16px;
}

button {
  border: 1px solid;
  border-radius: 3px;
  padding: 5px 10px;

  & + button {
    margin-left: 5px;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:active {
    outline: none;
  }
}

* {
  box-sizing: border-box;
}

a {
  color: var(--color-magenta);
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

.text-green {
  color: var(--color-green);
}
.text-cyan {
  color: var(--color-cyan);
}
.text-red {
  color: var(--color-red);
}
.text-blue {
  color: var(--color-blue);
}
.text-magenta {
  color: var(--color-magenta);
}
.text-yellow {
  color: var(--color-yellow);
}
.text-muted {
  color: var(--color-text-muted);
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

strong {
  font-weight: 600;
}

#app {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
}

main.main-wrapper {
  grid-area: main;
  padding: 30px 0;
  position: relative;
}

.loading-logo {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation: pulse 1s ease-in-out 0s infinite normal both;
}

.d-none {
  display: none !important;
}

.d-lg-inline {
  @media screen and (min-width: 992px) {
    display: inline !important;
  }
}
.d-lg-inline-block {
  @media screen and (min-width: 992px) {
    display: inline-block !important;
  }
}

.no-scroll {
  overflow: hidden;
}

body.modal-open {
  overflow: hidden;
}
